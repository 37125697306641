.ms-scrollbar::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.ms-scrollbar::-webkit-scrollbar-track {
  background: rgb(var(--color-contrast-low)); /* color of the tracking area */
}

.ms-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(var(--color-primary)); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
