.accordion {
  --bs-accordion-border-radius: 0px;
  --bs-accordion-inner-border-radius: 0px;
  --bs-accordion-bg: rgb(var(--color-bg));
}

.accordion-item {
  border: var(--bs-accordion-border-width) solid;
  border-color: rgba(var(--color-contrast-higher), 0.25);
  border-left: 0px;
  border-right: 0px;
}

.accordion-button.collapsed {
  color: rgb(var(--color-contrast-higher));
}
.accordion-button:not(.collapsed) {
  background-color: rgb(var(--color-bg));
  color: rgb(var(--color-contrast-higher));
  border: 10px;
  box-shadow: none;
}

.accordion-button:focus {
  background-color: rgb(var(--color-bg));
  color: rgb(var(--color-contrast-higher));
  border: 0px;
  box-shadow: none;
}

::after {
  filter: invert(97%) sepia(25%) saturate(48%) hue-rotate(203deg)
    brightness(121%) contrast(100%);
}
