.color-details {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.color-details .color-display {
  height: 50px;
  width: 50px;
  border: 3px solid transparent;
  background-color: rgb(var(--color-contrast-medium));
  border-radius: 50%;
  display: inline-block;
  margin: 0 auto;

  padding-bottom: 10px;
}

.color-details .color-display.Selected {
  border: 3px solid rgb(var(--color-bg));
  box-shadow: 0 0 0 2px rgb(var(--color-primary));
}

.color-details .color-display.Initial,
.color-details .color-display.Excluded {
  cursor: pointer;
}

.color-details .color-display.Incompatible:hover {
  cursor: not-allowed;
}

.color-details .color-name {
  margin-top: 10px;
  font-size: 0.75rem;
  text-align: center;
  color: white;
}
