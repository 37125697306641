.ms-select,
.ms-input {
  width: 100%;
  height: 50px;
  border: solid 1px rgba(var(--color-contrast-higher), 0.25);
  background-color: rgba(var(--color-contrast-higher), 0.03);
  color: rgb(var(--color-contrast-higher));
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
}

.ms-input--required {
  color: rgba(var(--color-contrast-higher), 0.5);
  font-size: 0.875rem;
  padding-left: 5px;
  padding-bottom: 7px;
}

.ms-select:focus,
.ms-input:focus {
  color: rgb(var(--color-contrast-higher));
  border-color: rgb(var(--color-primary));
  outline: 0;
  //box-shadow: 0 0 0 0.25rem rgba(52, 193, 158, 25%);
  box-shadow: 0 0 0 0.2rem rgba(var(--color-primary), 25%);
}

.ms-select:focus {
  background-color: rgb(var(--color-bg));
}
