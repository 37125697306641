.product-configuration {
  height: calc(100% - 56px);
}

.product-configuration__panel {
  height: 100%;
}

.product-features {
  flex-grow: 1;
  position: relative;
  overflow: auto;
}

.product-features__withprice {
  height: calc(100% - 80px - 40px);
}

.product-features__withoutprice {
  height: calc(100% - 80px);
}
.product-features__col {
  position: absolute;
}

@media (max-width: 992px) {
  .product-configuration {
    overflow: auto;
    height: calc(100% - 56px);
  }

  .product-configuration__panel {
    height: auto;
  }

  .product-features {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
  }

  .product-features__panel {
    flex-grow: 1;
    position: relative;
    height: auto;
  }

  .product-features__withprice {
    height: calc(100% - 56px);
  }

  .product-features__withoutprice {
    height: 100%;
  }

  .product-features__col {
    position: relative;
  }
}

/*
@media (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
  .product-configuration {
    overflow: auto;
    height: calc(100% - 56px);
  }

  .product-configuration__panel {
    height: auto;
  }

  .product-features {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
  }

  .product-features__panel {
    flex-grow: 1;
    position: relative;
    height: auto;
  }

  .product-features__withprice {
    height: calc(100% - 80px - 40px);
  }

  .product-features__withoutprice {
    height: 100%;
  }

  .product-features__col {
    position: relative;
  }
}
*/
