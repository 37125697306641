article {
  background-color: rgb(var(--color-contrast-low));
  padding: 12px;
  color: rgb(var(--color-contrast-higher));
}

article.Selected {
  box-shadow: 0 0 0 2px rgb(var(--color-primary));
  background: rgba(var(--color-primary), 0.15);
}

article.Initial,
article.Excluded,
article.Required,
article.Available {
  cursor: pointer;
}

article.Incompatible {
  color: rgba(var(--color-contrast-higher), 0.5);
  cursor: default;
}
/*
article.Incompatible:hover {
  cursor: not-allowed;
}*/

article .attribute-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.125em;
  font-weight: 600;
  line-height: 1.625;
  letter-spacing: -0.011em;
}

article .attribute-header .list-price {
  display: flex;
  gap: 0.5em;
}

article .attribute-description {
  text-align: left;
  font-size: 0.875;
  line-height: 1.313rem;
}
