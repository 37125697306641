.logo {
  object-fit: contain;
}

.logo-bigbox {
  max-height: 100px;
  max-width: 200px;
}

@media (min-width: 992px) {
  .logo-bigbox {
    max-height: 200px;
    max-width: 400px;
  }
}

.logo-header {
  max-height: 16px;
}
