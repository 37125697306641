/*.color-palette {
}
*/
.color-palette .color-groups {
  color: rgba(var(--color-contrast-higher), 0.75);
  font-weight: 400;
  font-size: 1rem;
}

.color-palette .color-groups ul.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 2px solid rgba(var(--color-contrast-higher), 0.25);
  margin-bottom: 12px;
}

.color-palette .color-groups ul.nav li {
  list-style: none;
  line-height: 1.5rem;
  margin-bottom: -2px;
  cursor: pointer;
  text-align: center;
  float: left;
}

.color-palette .color-groups ul.nav li.active {
  color: rgb(var(--color-primary));
  font-weight: 600;
  border-bottom: 2px solid rgb(var(--color-primary));
}

.color-palette .color-options {
  color: #324213;
  padding: 0px;
}
/*
gap: 12px;

display: grid;
  grid-template-columns: repeat(5, 75px);
  grid-template-rows: minmax(120px, max-content);
*/
