.product-body {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: rgb(var(--color-contrast-low));
  gap: 1.5em;
  padding: 32px;
}

.product-body .image img {
  max-width: 100%;
  max-height: 100%;
}

.product-body .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;
}

.product-body .details .product-name {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 500;
  padding-left: 0px;
}

.product-body .details .product-pricetag {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
  padding: 0px;
}

.product-body .details .description {
  flex: 0 1 auto;
  font-size: 1.125rem;
}

.product-body .details button {
  width: 100%;
  padding: 12px 32px;
  font-size: 1.125em;
  background-color: rgb(var(--color-primary));
  color: rgb(var(--color-contrast-higher));
  border: none;
  margin-top: auto;
  align-self: flex-end;
}

@media (max-width: 992px) {
  .product-body {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  .product-body .image {
    border-right: none;
  }

  .product-name {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .product-pricetag {
    font-size: 1.125rem;
    line-height: 1.7rem;
  }
}

@media (max-width: 576px) {
  .product-body {
    margin-bottom: 16px;
  }

  .product-name {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .product-pricetag {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
