:root {
  --color-primary: #{$color-primary};

  --color-bg: #{$color-bg};
  --color-contrast-lower: #{$color-contrast-lower};
  --color-contrast-low: #{$color-contrast-low};
  --color-contrast-medium: #{$color-contrast-medium};
  --color-contrast-higher: #{$color-contrast-higher};

  --color-danger: #{$color-danger};
}
