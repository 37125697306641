.ms-button {
  height: 50px;
  font-size: 0.9rem;
  border: solid 1px;
  border-radius: 0%;
}

@media (min-width: 576px) {
  .ms-button {
    height: 50px;
    font-size: 1.125rem;
    border: solid 1px;
    border-radius: 0%;
  }
}

.ms-button--primary {
  color: var(--white);
  border-color: rgb(var(--color-primary));
  background-color: rgb(var(--color-primary));

  &:hover {
    filter: brightness(115%);
  }
}

.ms-button--secondary {
  color: rgb(var(--color-primary));
  background-color: transparent;
  padding: 0px;

  &:hover {
    background-color: rgb(var(--color-primary));
    border-color: rgb(var(--color-primary));
    color: rgb(var(--color-contrast-higher));
    filter: brightness(115%);
  }
}

.button-container {
  display: flex;
  align-items: center;
  text-align: center;
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  color: rgb(var(--color-contrast-higher));
  text-decoration: none;

  vertical-align: middle;

  &:hover {
    --bs-link-hover-color: rgb(var(--color-contrast-higher));
  }
}
