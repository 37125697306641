.contact-form {
  height: calc(100% - 56px);
}

.contact-form__panel {
  height: 100%;
}

.contact-form__details {
  flex-grow: 1;
  height: calc(100% - 80px);
  overflow: auto;
  position: relative;
}

.contact-form__inputs {
  position: absolute;
}
.contact-form__input {
  padding: 7px 25px 7px 25px;
}

.contact-form__privacy {
  font-size: 0.875rem;
  padding: 0px 25px 7px 0px;
  margin-top: -2px;
}

.contact-form__href {
  --bs-link-color: rgb(var(--color-primary));
}

.contact-form__href:hover {
  --bs-link-hover-color: rgb(var(--color-primary));
}

/*(max-width: 576px) */
@media (max-width: 992px) {
  .contact-form__details {
    flex-grow: 1;
    height: calc(100% - 80px);
    overflow: hidden;
    position: relative;
  }

  .contact-form__inputs {
    position: relative;
  }
  .contact-form {
    overflow: auto;
    height: calc(100% - 56px);
  }

  .contact-form__panel {
    height: auto;
  }
}
