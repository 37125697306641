.accordion-header__feature {
  margin-bottom: 10px;
}

.accordion-header__optional {
  color: rgba(var(--color-contrast-higher), 0.5);
  font-size: 0.875rem;
  padding-left: 5px;
}

.accordion-header__name {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.687rem;
  margin-bottom: 0;
}

.accordion-header__option-selected {
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 0px;
}

.accordion-header__option-price {
  display: flex;
  gap: 0.5em;
  font-weight: 500;
}

/*
border-bottom: var(--bs-accordion-border-width) solid rgb(241, 6, 6) !important;
*/
