.product-price {
  font-size: 1.2rem;
  font-weight: 300;
  background-color: rgb(var(--color-contrast-lower));
  padding-top: 10px;
  height: 40px;
}

.product-price__amount {
  font-weight: 500;
}
