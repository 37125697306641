.configurator-loading {
  text-align: center;
  color: rgba(var(--color-contrast-higher), 0.75);
  font-size: 1.25rem;
}

.configurator-loading__clogo {
  max-height: 100px;
  max-width: 200px;
}

@media (min-width: 768px) {
  .configurator-loading__clogo {
    max-height: 200px;
    max-width: 400px;
  }
}

.configurator-loading__cname {
  font-size: 1.5rem;
  color: transparent;
}

.configurator-loading__poweredby {
  float: right;
  font-size: 0.875rem;
}
