.modal-content {
  --bs-modal-bg: rgb(var(--color-contrast-low)); //rgb(var(--color-bg));
  --bs-modal-border-radius: 0rem;
}

.modal-header {
  background-color: rgb(var(--color-contrast-medium));
  border-bottom: none;
}

.modal-footer {
  background-color: rgb(var(--color-contrast-medium));
  border-top: none;
}
.modal {
  --bs-modal-inner-border-radius: none;
}
.modal-lg,
modal-xl {
  --bs-modal-width: 550px;
}
