.configurator-main {
  height: calc(100% - 56px);
}

/*@media (max-width: 576px) {*/
@media (max-width: 992px) {
  .configurator-viewer {
    height: 40%;
  }
  .configurator-panel {
    height: 60%;
  }
}

/*.configurator-main {
    height: 100%;
  }*/
@media (min-width: 992px) {
  .configurator-panel {
    width: 480px;
  }
}

@media (max-width: 992px) and (orientation: landscape) {
  .col-md-4-ls {
    float: left;
  }
  .col-md-ls {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0%;
  }
  .col-md-4-ls {
    width: 40%; //33.33333333%;;
  }

  .configurator-viewer {
    height: 100%;
  }
  .configurator-panel {
    height: 100%;
  }
}
