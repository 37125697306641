.product-slider {
  display: flex;
  flex-direction: column;
  /*margin: 32px;*/
  width: 100%;
  justify-content: center;
  color: rgb(var(--color-contrast-higher));
}

.product-slider .product-title {
  font-weight: 500;
  font-size: 3.5rem;
  line-height: 150%;
  margin-bottom: 25px;
  text-align: center;
}

.product-slider .product-nav-dots {
  text-align: center;
}

.product-slider .product-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 4fr 1fr;
}

.product-slider .product-container .left-arrow,
.product-slider .product-container .right-arrow {
  justify-self: center;
  align-self: center;
  font-size: 3em;
  /* background-color: #36454f; */
  border-radius: 50%;
  cursor: pointer;
}

.product-slider .product-nav-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  margin-top: 8px;
}

.product-slider .product-nav-dots .dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgb(var(--color-contrast-medium));
}

.product-slider .product-nav-dots .dots.active {
  width: 8px;
  height: 8px;
  background-color: rgb(var(--color-contrast-higher));
}

@media (max-width: 992px) {
  .product-slider .product-title {
    font-size: 3rem;
    line-height: 3.75;
  }
}

@media (max-width: 768px) {
  .product-slider .product-title {
    font-size: 2.5rem;
    line-height: 3.75;
  }
}

@media (max-width: 576px) {
  .product-slider .product-container .left-arrow,
  .product-slider .product-container .right-arrow,
  .product-slider .product-nav-dots {
    display: none;
  }

  .product-slider .product-container {
    grid-template-columns: 1fr;
  }

  .product-slider .product-title {
    font-size: 2rem;
  }
}
