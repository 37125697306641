/* Style the links inside the navigation bar */
.nav-menu a {
  color: rgb(var(--color-contrast-higher));
  text-align: center;
  padding: 22px 24px 22px 24px;

  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
}

/* Change the color of links on hover */
.nav-menu a:hover {
  color: rgb(var(--color-primary));
}

.navbar {
  padding: 8px 8px 8px 8px;
}

.nav-link {
  padding: 8px;
}

.header {
  left: 0;
  right: 0;
}

@media (max-width: 992px) {
  .nav-menu {
    display: none;
  }
}
