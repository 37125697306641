body {
  font-family: var(--font-family);
  font-size: 16px;
  color: rgb(var(--color-contrast-higher));
  background-color: rgb(var(--color-bg));
  height: 100vh;
}

body::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-primary); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
